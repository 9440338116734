* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
    outline: 0;
    color: #4e4e4e;
}
